import React from "react"
import PropTypes from "prop-types"
import { FormattedMessage, FormattedHTMLMessage } from "react-intl"

import Layout from "../components/layout"
import PanelImageOne from "../images/noad.svg"
import PanelImageTwo from "../images/offline.svg"
import PanelImageThree from "../images/coins.svg"
import PanelImageFour from "../images/premium-picks.png"
import BeigePortalCenter from "../images/beige-portal-center.svg"
import BeigePortalCenterMobile from "../images/beige-portal-center-mobile.svg"

import PremiumLogoBlack from "../images/premium-logo-black.svg"
import PremiumPlusLogoBlack from "../images/premium-plus-logo-black.svg"

import PremiumPlusCoin from "../images/premium-plus-coin.svg"
import PremiumPlusPerk from "../images/premium-plus-perk.svg"

import PlusIcon from "../images/plus-icon.svg"

import CheckmarkRed from "../images/checkmark-red.svg"
import CheckmarkBlue from "../images/checkmark-blue.svg"

import PaperBoats from "../images/paper-boats.png"

import HomeMobileHeader from "../images/HeroBanner-320.png"

import SEO from "../components/seo"

import { START_FREE_WEEK_LINK, PREMIUM_PLUS_LINK } from "../constants/links"

import "../styles/flickity.css"

const Flickity =
  typeof window !== `undefined` ? require("react-flickity-component") : null

const IndexPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale} location={location}>
    <SEO
      title="Wattpad Premium | Supercharge Your Wattpad Experience"
      keywords={[`Premium`, `Wattpad`]}
    />

    <div className="header-padding-offset">
      <div className="section purple-bg" id="header-section">
        <img
          src={HomeMobileHeader}
          alt="Wattpad Premium"
          className="hide-large-tablet-desktop"
        />

        <div className="hide-small-tablet-phones main-cta">
          <p className="page-header white-txt">
            <FormattedHTMLMessage id="header" />
          </p>
          <p className="light-txt font-truemed pad-stsb pad-t white-txt">
            <FormattedMessage id="ctaOnePrimary" />
          </p>
          <div className="flex pad-ts">
            <StartFreeWeek />
          </div>
        </div>

        <div
          className="section pad-tbl hide-large-tablet-desktop"
          id="go-premium"
        >
          <div className="section-inner">
            <p className="font-small white-txt page-header">
              <FormattedHTMLMessage id="header" />
            </p>
            <p className="light-txt font-smaller pad-stsb white-txt">
              <FormattedMessage id="ctaOnePrimary" />
            </p>
            <div className="download-wrapper">
              <StartFreeWeek />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="section pad-tl white-bg" id="enjoy-section">
      <div className="section-inner">
        <p className="regular-txt section-text-header">
          <FormattedMessage id="benefitsHeader" />
        </p>
        <div className="panel-wrapper pad-t">
          <Panel
            imgSrc={PanelImageOne}
            alt="Hand holding sheet"
            messageHeader={<FormattedHTMLMessage id="benefitOneHeader" />}
            messageBody={<FormattedMessage id="benefitOneBody" />}
          />
          <Panel
            imgSrc={PanelImageTwo}
            alt=""
            messageHeader={<FormattedMessage id="benefitTwoHeader" />}
            messageBody={<FormattedHTMLMessage id="benefitTwoBody" />}
          />
          <Panel
            imgSrc={PanelImageThree}
            alt="Premium Themes"
            messageHeader={<FormattedMessage id="benefitThreeHeader" />}
            messageBody={<FormattedHTMLMessage id="benefitThreeBody" />}
          />
          <Panel
            imgSrc={PanelImageFour}
            alt="Premium Go Further"
            messageHeader={<FormattedHTMLMessage id="benefitFourHeader" />}
            messageBody={<FormattedHTMLMessage id="benefitFourBody" />}
          />
        </div>
      </div>
    </div>

    <div className="section pad-tb base-1-bg" id="premium-plus">
      <div id="premium-plus-portal">
        <img
          src={BeigePortalCenter}
          alt="Background of beige curves"
          className="hide-small-tablet-phones"
        />

        <img
          src={BeigePortalCenterMobile}
          alt="Background of beige curves"
          className="hide-large-tablet-desktop"
        />
      </div>
      <div className="section-inner">
        <p className="regular-txt section-text-header">
          <FormattedMessage id="premiumPlusHeader" />
        </p>
        <div className="premium-plus-benefits">
          <div className="premium-plus-benefit padding-all">
            <div className="benefit-image">
              <img src={PremiumPlusCoin} alt="Premium Plus Paid Story" />
            </div>
            <div className="benefit-copy">
              <p className="bold-txt black-txt font-small pad-stsb premium-plus-benefit-header">
                <FormattedMessage id="premiumPlusOneHeader" />
              </p>
              <p className="premium-plus-benefit-body light-txt">
                <FormattedHTMLMessage
                  className="regular-txt"
                  id="premiumPlusOneBody"
                />
              </p>
            </div>
          </div>
          <div className="plus-icon-container">
            <img className="plus-icon" src={PlusIcon} alt="Plus icon" />
          </div>
          <div className="premium-plus-benefit padding-all">
            <div className="benefit-image">
              <img src={PremiumPlusPerk} alt="Premium Plus Perk" />
            </div>
            <div className="benefit-copy">
              <p className="bold-txt black-txt font-small pad-stsb premium-plus-benefit-header">
                <FormattedMessage id="premiumPlusTwoHeader" />
              </p>
              <p className="premium-plus-benefit-body light-txt">
                <FormattedMessage id="premiumPlusTwoBody" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="section" id="get-premium">
      <div id="get-premium-img-top">
        <img src={PaperBoats} alt="Paper Boats" />
      </div>

      <div className="">
        <div className="panel-wrapper hide-tablet-plus-desktop">
          <div className="carousel-wrapper">
            <PricingPlansCarousel />
          </div>
        </div>

        <div className="panel-wrapper hide-tablet-minus-phone">
          <PremiumPlusPlan />
          <PremiumPlan />
        </div>
      </div>
    </div>
  </Layout>
)

IndexPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

function Panel({ imgSrc, alt, messageHeader, messageBody }) {
  return (
    <div className="panel-inner-wrapper padding-all">
      <div className="panel text-center">
        <img src={imgSrc} alt={alt} />
        <div className="panel-copy-container">
          <p className="bold-txt black-txt font-small pad-stsb">
            {messageHeader}
          </p>
          <p className="light-txt black-txt font-16px">{messageBody}</p>
        </div>
      </div>
    </div>
  )
}

function StartFreeWeek({ shortForm }) {
  if (shortForm) {
    return (
      <a className="btn-secondary" href={START_FREE_WEEK_LINK}>
        <FormattedMessage id="startFreeWeekShort" />
      </a>
    )
  }

  return (
    <a className="btn-secondary" href={START_FREE_WEEK_LINK}>
      <FormattedMessage id="startFreeWeek" />
    </a>
  )
}

function PricingPlansCarousel() {
  if (typeof window === "undefined") return null

  return (
    <Flickity>
      <PremiumPlusPlan />
      <PremiumPlan />
    </Flickity>
  )
}

function PremiumPlusPlan({ onLoad = () => {} }) {
  return (
    <PricingPlan
      bgColor="white"
      textColor="#E81249"
      logo={PremiumPlusLogoBlack}
      price={<FormattedHTMLMessage id="pricingOnePriceOne" />}
      priceDetails={<FormattedMessage id="pricingOneSubHeader" />}
      planLabel={<FormattedMessage id="pricingOneLabel" />}
      pricingPoints={[
        <FormattedHTMLMessage id="pricingOneBodyOne" />,
        <FormattedHTMLMessage id="pricingOneBodyPremiumPicks" />,
        <FormattedMessage id="pricingOneBodyTwo" />,
        <FormattedMessage id="pricingOneBodyThree" />,
        <FormattedMessage id="pricingOneBodyFour" />,
        <FormattedMessage id="pricingOneBodyFive" />,
      ]}
      checkmark={CheckmarkRed}
      priceCta={<FormattedMessage id="pricingOneCTA" />}
      priceCtaDetails={
        <>
          <FormattedMessage id="pricingOneCTADetailsOne" />
        </>
      }
      onLoad={onLoad}
      branchLink={PREMIUM_PLUS_LINK}
    />
  )
}

function PremiumPlan({ onLoad }) {
  return (
    <PricingPlan
      bgColor="#F6F2FF"
      textColor="#2A1C60"
      logo={PremiumLogoBlack}
      price={<FormattedHTMLMessage id="pricingTwoPriceOne" />}
      priceDetails={<FormattedMessage id="pricingTwoSubHeader" />}
      planLabel={<FormattedMessage id="pricingLabel" />}
      pricingPoints={[
        <FormattedHTMLMessage id="pricingTwoBodyPremiumPicks" />,
        <FormattedHTMLMessage id="pricingTwoBodyOne" />,
        <FormattedMessage id="pricingTwoBodyTwo" />,
        <FormattedMessage id="pricingTwoBodyThree" />,
        <FormattedMessage id="pricingTwoBodyFour" />,
      ]}
      checkmark={CheckmarkBlue}
      priceCta={<FormattedMessage id="startFreeWeekShort" />}
      priceCtaDetails={
        <span className="top-content">
          <FormattedMessage id="pricingTwoCTADetails" />
        </span>
      }
      ctaDetailsInvisibleContent={
        <>
          <FormattedMessage id="pricingOneCTADetailsOne" />
        </>
      }
      onLoad={onLoad}
      branchLink={START_FREE_WEEK_LINK}
    />
  )
}

function PricingPlan({
  bgColor,
  textColor,
  logo,
  price,
  priceDetails,
  planLabel,
  pricingPoints,
  checkmark,
  priceCta,
  priceCtaDetails,
  ctaDetailsInvisibleContent,
  onLoad,
  branchLink,
}) {
  return (
    <div className="panel-inner-wrapper" style={{ backgroundColor: bgColor }}>
      <div className="panel">
        <img className="premium-logo" src={logo} alt="Premium Plus logo" />
        <div className="panel-inner">
          <div className="panel-copy-container text-center">
            <p
              className="regular-txt font-small price"
              style={{ color: textColor }}
            >
              {price}
            </p>
            {priceDetails && (
              <p className="neutral-2-txt font-xsmall pricing-details light-txt">
                {priceDetails}
              </p>
            )}
          </div>
          <div
            className="regular-txt plan-label m-t"
            style={{ backgroundColor: textColor }}
          >
            {planLabel}
          </div>
          <div className="panel-copy-container list-indent pad-tbm">
            {pricingPoints.map((p, i) => (
              <div className="pricing-point" key={i}>
                <img src={checkmark} alt="Checkmark" onLoad={onLoad} />
                <p className="regular-txt black-txt font-16px">{p}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div>
        <a className="btn-primary" href={branchLink}>
          {priceCta}
        </a>
        <p className="neutral-2-txt regular-txt font-xxsmall cta-details">
          {priceCtaDetails}
          <span className="invisible-content">
            {ctaDetailsInvisibleContent}
          </span>
        </p>
      </div>
    </div>
  )
}

export default IndexPage
